<template>
  <el-dialog
    v-loading.fullscreen.lock="loadingFlag"
    class="share-dialog"
    title="分享"
    :visible.sync="visible"
    :shareDialogVisible="shareDialogVisible"
    :append-to-body="true"
    :destroy-on-close="true"
    @before-close="handleClose"
    @close="handleClose"
    width="40%"
  >
    <el-form
      ref="shareForm"
      :rules="rules"
      :model="shareForm"
      label-width="6.25vw"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="请选择：" prop="personList">
            <el-cascader
              :options="options"
              :props="props"
              clearable
              :show-all-levels="false"
              @change="handleShareSelect"
              popper-class="choose-cascader"
              v-if="shareType == 'headquarters' || shareType == 'company'"
            ></el-cascader>
            <!-- <el-select v-model="shareSelectList" multiple placeholder="请选择" v-if="shareType == 'school'" @change="handleShareSchoolSelect">
              <el-option v-for="item in options" :key="item.label" :label="item.name" :value="item.label"></el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button :loading="shareButtonloading" @click="handleClose"
        >取 消</el-button
      >
      <el-button
        :loading="shareButtonloading"
        type="primary"
        @click="handleSubmit"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "shareDialog",
  props: {
    shareDialogVisible: {
      type: Boolean,
      default: false,
    },
    shareType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false, // 弹窗显隐标识
      loadingFlag: false, //页面请求loading
      shareForm: {
        personList: [],
      }, //表单数据
      shareSelectList: [], //分享园校下拉
      rules: {
        personList: [
          { required: true, message: "请选择要分享的园校", trigger: "change" },
        ],
      }, //校验规则
      shareButtonloading: false, //按钮loading

      props: {
        multiple: true,
        value: "label",
        label: "name",
        children: "child",
      }, //组织级联配置
      options: [], //级联下拉
      userInfo: {}, //登录信息
    };
  },
  created() {
    let userInfo = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : {};
    this.userInfo = userInfo;
  },
  watch: {
    shareDialogVisible(val) {
      this.visible = val;
    },
    shareType(val) {
      if (val) {
        this.loadingFlag = true;
        this.getOptions(val);
      }
    },
  },
  methods: {
    //关闭弹窗事件
    handleClose() {
      this.shareForm = {
        personList: [],
      };
      this.shareButtonloading = false;
      this.$refs.shareForm.clearValidate();
      this.$emit("returnFun", { flag: false });
    },
    //获取选项
    getOptions(type) {
      this.$api
        .getShareTreeInfo()
        .then((res) => {
          if (res.data.code == 0) {
            this.loadingFlag = false;
            res.data.data.map((item) => {
              if (item.name === "总部") {
                item.label = "zb";
              }
              if (item.child && item.child.length > 0) {
                item.child.map((info) => {
                  if (info.child && info.child.length > 0) {
                    info.child.map((thirdInfo) => {
                      if (thirdInfo.isPost == "0") {
                        thirdInfo.disabled = true;
                      }
                    });
                  } else {
                    if (info.isPost == "0") {
                      info.disabled = true;
                    }
                  }
                });
              } else {
                if (item.isPost == "0") {
                  item.disabled = true;
                }
              }
            });
            this.options = res.data.data;
          } else {
            this.loadingFlag = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.loadingFlag = false;
        });
    },
    //改变选择事件
    handleShareSelect(val) {
      console.log(val);
      let list = [];
      val.map((item) => {
        let info = null;
        let orgInfo = {};
        let lastLevelInfo = {};
        let jobInfo = {};
        if (item.length === 1) {
          jobInfo = this.options.find((jobGetInfo) => {
            return jobGetInfo.label == item[0];
          });
          if (jobInfo) {
            info = {};
            info.jobId = item[0];
            info.jobName = orgInfo.name;
          }
        }
        if (item.length === 2) {
          lastLevelInfo = this.options.find((lastLevelGetInfo) => {
            return lastLevelGetInfo.label == item[0];
          });
          jobInfo = lastLevelInfo.child.find((jobGetInfo) => {
            return jobGetInfo.label == item[1];
          });
          if (jobInfo) {
            info = {};
            info.orgId = item[0];
            info.orgName = lastLevelInfo.name;
            info.jobId = item[1];
            info.jobName = jobInfo.name;
          }
        }
        if (item.length === 3) {
          orgInfo = this.options.find((orgGetInfo) => {
            return orgGetInfo.label == item[0];
          });
          lastLevelInfo = orgInfo.child.find((lastLevelGetInfo) => {
            return lastLevelGetInfo.label == item[1];
          });
          jobInfo = lastLevelInfo.child.find((jobGetInfo) => {
            return jobGetInfo.label == item[2];
          });
          if (jobInfo) {
            info = {};
            info.orgId = item[1];
            info.orgName = lastLevelInfo.name;
            info.jobId = item[2];
            info.jobName = jobInfo.name;
          }
        }
        if (info) {
          list.push(info);
        }
      });
      console.log(list);
      if (list.length > 0) {
        this.shareForm.personList = list;
      }
    },
    //提交事件
    handleSubmit() {
      this.$refs.shareForm.validate((valid) => {
        if (valid) {
          this.shareButtonloading = true;
          this.$emit("returnFun", {
            personList: this.shareForm.personList,
            flag: true,
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-footer {
  /deep/.el-button--primary {
    width: auto;
  }
}

.share-dialog {
  /deep/ .el-cascader {
    width: 586px;
  }

  /deep/ .el-cascader__tags {
    height: 28px;
    overflow: auto;
  }
}
</style>