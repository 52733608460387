<template>
  <el-dialog class="supervise-dialog" title="督办" :visible.sync="visible" :superviseDialogVisible="superviseDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="30%">
    <el-form ref="superviseForm" :rules="rules" :model="superviseForm" label-width="6.25vw">
      <el-row>
        <el-col :span="23">
          <el-form-item label="督办建议：" ><!--prop="suggestion"-->
          <!-- :maxlength="200" :rows="4"  resize="none"-->
            <el-input type="textarea" maxlength="200" rows="6" placeholder="请输入督办建议" show-word-limit v-model="superviseForm.suggestion" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button :loading="superviseButtonloading" @click="handleClose">取 消</el-button>
      <el-button :loading="superviseButtonloading" type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'superviseDialog',
  props: {
    superviseDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      visible: false,// 弹窗显隐标识
      superviseForm: {
        suggestion: ""
      },//表单数据
      rules: {
        suggestion: [{ required: true, message: "请输入督办建议", trigger: "blur" }],
      },//校验规则
      superviseButtonloading: false, //按钮loading
    }
  },
  watch: {
    superviseDialogVisible(val){
      this.visible = val
    }
  },
  methods: {
    handleClose(){
      this.superviseForm = {
        suggestion: ""
      }
      this.superviseButtonloading = false
      this.$refs.superviseForm.clearValidate()
      this.$emit("returnFun",{ flag: false })
    },
    handleSubmit(){
      this.$refs.superviseForm.validate((valid) => {
        if (valid) {
          this.superviseButtonloading = true
          this.$emit("returnFun",{ suggestion: this.superviseForm.suggestion, flag: true })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.dialog-footer{
  /deep/.el-button--primary{
    width: auto;
  }
}
</style>