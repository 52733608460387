<template>
  <el-dialog class="refresh-time-dialog" title="延长时间" :visible.sync="visible" :refreshTimeDialogVisible="refreshTimeDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="40%">
    <el-form ref="refreshTimeForm" :rules="rules" :model="refreshTimeForm" label-width="6.25vw">
      <el-row>
        <el-col :span="24">
          <el-form-item label="截止日期：" prop="endDate">
            <el-date-picker v-model="refreshTimeForm.endDate" type="date" placeholder="请选择截止日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button :loading="refreshTimeButtonloading" @click="handleClose">取 消</el-button>
      <el-button :loading="refreshTimeButtonloading" type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'refreshTimeDialog',
  props: {
    refreshTimeDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      visible: false,// 弹窗显隐标识
      refreshTimeForm: {
        endDate: ""
      },//表单数据
      rules: {
        endDate: [{ required: true, message: "请选择截止日期", trigger: "change" }],
      },//校验规则
      refreshTimeButtonloading: false, //按钮loading
    }
  },
  watch: {
    refreshTimeDialogVisible(val){
      this.visible = val
    }
  },
  methods: {
    handleClose(){
      this.refreshTimeForm = {
        endDate: ""
      }
      this.refreshTimeButtonloading = false
      this.$refs.refreshTimeForm.clearValidate()
      this.$emit("returnFun",{ flag: false })
    },
    handleSubmit(){
      this.$refs.refreshTimeForm.validate((valid) => {
        if (valid) {
          this.refreshTimeButtonloading = true
          this.$emit("returnFun",{ endDate: this.refreshTimeForm.endDate, flag: true })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.dialog-footer{
  /deep/.el-button--primary{
    width: auto;
  }
}
</style>