<template>
  <!-- 页面name：督导模板 -->
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">任务列表</div>
      <div></div>
    </div>
    <!-- 表单 -->
    <el-form
      :model="form"
      onsubmit="return false"
      :inline="true"
      class="search-form"
    >
      <el-form-item>
        <el-select
          v-model="form.parkId"
          class="select-input"
          clearable
          placeholder="请选择园校"
        >
          <el-option
            v-for="item in schoolOptions"
            :key="item.label"
            :label="item.name"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.jobId"
          class="select-input"
          clearable
          placeholder="请选择岗位"
        >
          <el-option
            v-for="item in postOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.supervisionTopic"
          class="select-input"
          placeholder="请输入督导主题"
          @change="handleTaskSupervisionTopic"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.supervisionMethod"
          class="select-input"
          clearable
          placeholder="请选择督导方式"
        >
          <el-option
            v-for="item in superviseTypeOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="publishTime"
          class="select-time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="发布开始时间"
          end-placeholder="发布结束时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="handlePublishTime"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.publishName"
          class="select-input"
          placeholder="请输入发布人"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.status"
          class="select-input"
          clearable
          placeholder="请选择流程状态"
        >
          <el-option
            v-for="item in flowStatusShowOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh-right" @click="resetFun"
          >重置</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="isSelfPublish" @change="handleSelfFun"
          >自己发布</el-checkbox
        >
      </el-form-item>
    </el-form>
    <div class="tab-buttons">
      <el-radio-group
        v-model="activeTab"
        @change="changeTab"
        style="margin-bottom: 1.04167vw"
      >
        <el-radio-button label="all">全部</el-radio-button>
        <el-radio-button label="0">待办任务</el-radio-button>
        <el-radio-button label="1">已办任务</el-radio-button>
      </el-radio-group>
    </div>
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.91667vw)'"
      :autoHeight="false"
    >
      <el-table-column
        prop="parkName"
        label="园校"
        align="center"
        slot="parkName"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="jobName"
        label="岗位"
        align="center"
        width="200"
        slot="jobName"
      ></el-table-column>
      <el-table-column
        prop="supervisionTopic"
        label="督导主题"
        align="center"
        width="200"
        slot="supervisionTopic"
      ></el-table-column>
      <el-table-column
        prop="supervisionMethodName"
        label="督导方式"
        align="center"
        width="100"
        slot="supervisionMethodName"
      ></el-table-column>
      <el-table-column
        prop="totalQuestions"
        label="总题数"
        align="center"
        slot="totalQuestions"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="totalScore"
        label="总分数"
        align="center"
        slot="totalScore"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="publishName"
        label="发布人"
        align="center"
        width="90"
        slot="publishName"
      ></el-table-column>
      <el-table-column
        prop="sendTime"
        label="发布时间"
        width="150"
        align="center"
        slot="sendTime"
      ></el-table-column>
      <el-table-column
        prop="parkDeadline"
        label="截止日期"
        width="100"
        align="center"
        slot="parkDeadline"
      ></el-table-column>
      <el-table-column
        prop="statusName"
        label="流程状态"
        align="center"
        width="120"
        slot="statusName"
      ></el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        slot="handle"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            class="table-handle"
            @click="handleTaskFun('view', scope.row)"
            v-if="isView"
            type="text"
            >查看</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('refreshTime', scope.row)"
            type="text"
            v-if="
              (scope.row.status === '6' || scope.row.status === '7') &&
              scope.row.isTimeEdit
            "
            >延长时间</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('withdraw', scope.row)"
            type="text"
            v-if="scope.row.status === '4' && isDelete && scope.row.isEdit"
            >撤回</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('supervise', scope.row)"
            type="text"
            v-if="
              (scope.row.status == '1' ||
                scope.row.status == '6' ||
                scope.row.status == '4' ||
                scope.row.status == '5') &&
              isSupervise &&
              scope.row.isEdit
            "
            :disabled="scope.row.isSupervise === '1'"
            >{{ scope.row.isSupervise === "1" ? "已督办" : "督办" }}</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('viewAllEvaluate', scope.row)"
            type="text"
            v-if="
              (scope.row.status === '3' ||
                scope.row.status === '8' ||
                scope.row.status === '9') &&
              isTotalSore &&
              scope.row.isEdit
            "
            >查看总评</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('viewScore', scope.row)"
            type="text"
            v-if="
              (scope.row.status === '2' || scope.row.status === '7') &&
              isScore &&
              scope.row.isEdit
            "
            >评分</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('share', scope.row)"
            type="text"
            v-if="
              ((scope.row.status === '8' && !scope.row.groupAudit) ||
                scope.row.status === '9') &&
              isShare &&
              scope.row.isEdit
            "
            >分享</el-button
          >
        </template>
      </el-table-column>
    </l-table>
    <supervise-dialog
      ref="superviseDialog"
      @returnFun="superviseReturnFun"
      :superviseDialogVisible="superviseDialogVisible"
    ></supervise-dialog>
    <share-dialog
      ref="shareDialog"
      @returnFun="shareReturnFun"
      :shareDialogVisible="shareDialogVisible"
      :shareType="shareType"
    ></share-dialog>
    <refresh-time-dialog
      ref="refreshTimeDialog"
      @returnFun="refreshTimeReturnFun"
      :refreshTimeDialogVisible="refreshTimeDialogVisible"
    ></refresh-time-dialog>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
import SuperviseDialog from "./components/superviseDialog.vue";
import ShareDialog from "./components/shareDialog.vue";
import RefreshTimeDialog from "./components/refreshTimeDialog.vue";
export default {
  name: "agencyTaskCompany",
  components: { LTable, SuperviseDialog, ShareDialog, RefreshTimeDialog },
  data() {
    return {
      dialogVisible: false,
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "园校",
          slotName: "parkName",
        },
        {
          label: "岗位",
          slotName: "jobName",
        },
        {
          label: "督导主题",
          slotName: "supervisionTopic",
        },
        {
          label: "督导方式",
          slotName: "supervisionMethodName",
        },
        {
          label: "总题数",
          slotName: "totalQuestions",
        },
        {
          label: "总分数",
          slotName: "totalScore",
        },
        {
          label: "发布人",
          slotName: "publishName",
        },
        {
          label: "发布时间",
          slotName: "sendTime",
        },
        {
          label: "截止日期",
          slotName: "parkDeadline",
        },
        {
          label: "流程状态",
          slotName: "statusName",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "",
      form: {
        parkId: "",
        jobId: "",
        supervisionTopic: "",
        supervisionMethod: "",
        beginTime: "",
        endTime: "",
        publishName: "",
        status: "",
      },
      supervisionTopicName: "", //督导主题名称
      isSelfPublish: false, //自己发布
      publishTime: [], //发布时间选择的时间段
      schoolOptions: [], //园校下拉
      superviseTypeOptions: [
        {
          name: "远程督导",
          value: "1",
        },
        {
          name: "现场督导",
          value: "2",
        },
      ], //督导方式下拉
      flowStatusOptions: [
        {
          name: "园校待办",
          value: "1",
        },
        {
          name: "分公司待办",
          value: "2",
        },
        // {
        //   name: "总部审批",
        //   value: "3",
        // },
        {
          name: "分公司驳回",
          value: "4",
        },
        {
          name: "总部驳回",
          value: "5",
        },
        {
          name: "园校逾期待办",
          value: "6",
        },
        {
          name: "分公司逾期待办",
          value: "7",
        },
        {
          name: "总部未评分",
          value: "8",
        },
        {
          name: "已完成",
          value: "9",
        },
      ], //状态数据
      flowStatusShowOptions: [], //状态下拉
      tableData: [], //表单数据
      userInfo: {}, //登录用户信息

      superviseDialogVisible: false, //督办弹窗
      superviseDialogType: "", //督办类型
      taskId: null, //任务id

      shareDialogVisible: false, //分享标识
      shareType: "", //分享类型

      refreshTimeDialogVisible: false, //延长时间标识

      activeTab: "0", //tab选中值

      permissionButtonList: [], // 权限按钮list
      isView: false, //查看
      isScore: false, //评分
      isDelete: false, //撤回
      isTotalSore: false, //查看总评
      isSupervise: false, //督办
      isShare: false, //分享
      taskSupervisionTopic:'',
      dudaoTaskId:'',
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      postOptions: (state) => state.common.postList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isView = val.indexOf("f_agencyTask:view") != -1;
          this.isScore = val.indexOf("f_agencyTask:score") != -1;
          this.isDelete = val.indexOf("f_agencyTask:undo") != -1;
          this.isTotalSore = val.indexOf("f_agencyTask:viewScore") != -1;
          this.isSupervise = val.indexOf("f_agencyTask:supervise") != -1;
          this.isShare = val.indexOf("f_agencyTask:share") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.tableLoading = true;
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    let userInfo = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : {};
    this.userInfo = userInfo;

    let supervisionTopic = sessionStorage.getItem("supervisionTopic");
    this.supervisionTopicName = supervisionTopic ? supervisionTopic : "";
    this.form.supervisionTopic = supervisionTopic;
    this.taskSupervisionTopic = this.$route.query.taskSupervisionTopic
    this.form.supervisionTopic = this.$route.query.taskSupervisionTopic

    // this.flowStatusShowOptions = this.flowStatusOptions.filter((statusInfo) => {
    //   return statusInfo.value != 9;
    // });

    this.flowStatusShowOptions = this.flowStatusOptions;
    
    setTimeout(() => {
      this.getRangeList();
      // this.init();
    }, 300);
  },
  activated(){
    setTimeout(() => {
      this.init();
    }, 300);
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    
    //输入督导主题input框change事件
    handleTaskSupervisionTopic(val){
      this.taskSupervisionTopic = val
    },
    
    /** 初始化 */
    init() {
      let param = {};
      for (let key in this.form) {
        if (this.form[key]) {
          param[key] = this.form[key];
        }
      }
      param.type = "2";
      param.branchId = this.userInfo.schoolList[0].companyLabel;
      param.current = this.page.num;
      param.size = this.page.size;
      if(this.taskSupervisionTopic!==''){
        param.supervisionTopic = this.taskSupervisionTopic;
      }
      let funName = "";
      switch (this.activeTab) {
        case "all":
          funName = "getAgencyTaskAllList";
          break;
        case "0":
          funName = "getAgencyTaskDoingList";
          break;
        case "1":
          funName = "getAgencyTaskFinishList";
          break;
        default:
          break;
      }
      this.$api[funName](param)
        .then((res) => {
          if (res.data.code == 0) {
            this.tableLoading = false;
            res.data.data.records.map((item) => {
              let supervisionMethodName = "";
              let supervisionMethodIndex = this.superviseTypeOptions.findIndex(
                (superviseTypeInfo) => {
                  return superviseTypeInfo.value == item.supervisionMethod;
                }
              );
              if (supervisionMethodIndex != -1) {
                supervisionMethodName =
                  this.superviseTypeOptions[supervisionMethodIndex].name;
              }
              item.supervisionMethodName = supervisionMethodName;

              let statusName = "";
              let statusIndex = this.flowStatusOptions.findIndex(
                (statusInfo) => {
                  return statusInfo.value == item.status;
                }
              );
              if (statusIndex != -1) {
                statusName = this.flowStatusOptions[statusIndex].name;
              }
              item.statusName = statusName;

              // let jobName = ""
              // let jobIndex = this.postOptions.findIndex((postInfo) => { return postInfo.value == item.jobId })
              // if(jobIndex != -1){
              //   jobName = this.postOptions[jobIndex].name
              // }
              // item.jobName = jobName

              if (
                (item.branchAudit.indexOf(this.userInfo.userId) != -1 &&
                  (this.activeTab == "all" || this.activeTab == "1")) ||
                this.activeTab == "0"
              ) {
                item.isEdit = true;
              } else {
                item.isEdit = false;
              }

              if (
                item.publishUser == this.userInfo.userId &&
                item.level == "2" &&
                this.activeTab == "all"
              ) {
                item.isTimeEdit = true;
              } else {
                item.isTimeEdit = false;
              }
            });
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableLoading = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    //获取园校下拉
    getRangeList() {
      this.$api
        .getCompanyDept({
          parent: this.userInfo.schoolList[0].companyLabel,
          type: "NODE_SCHOOL",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.schoolOptions = res.data.data || [];
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //处理切换
    changeTab(val) {
      this.searchFun();
    },
    //时间变化事件
    handlePublishTime(val) {
      console.log(val);
      if (val && val.length > 0) {
        this.form.beginTime = val[0];
        this.form.endTime = val[1];
      } else {
        this.form.beginTime = "";
        this.form.endTime = "";
      }
    },
    //处理自我发布
    handleSelfFun(val) {
      if (val) {
        this.form.publishName = this.userInfo.userName;
      } else {
        this.form.publishName = "";
      }
      this.searchFun();
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.parkId = "";
      this.form.jobId = "";
      this.form.supervisionTopic = "";
      this.form.supervisionMethod = "";
      this.form.beginTime = "";
      this.form.endTime = "";
      if (!this.isSelfPublish) {
        this.form.publishName = "";
      }
      this.publishTime = [];
      this.form.status = "";
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
    //督办返回事件
    superviseReturnFun(data) {
      if (data.flag) {
        let suggestion = data.suggestion;
        let params = {
          orderId: this.taskId,
          level: "2",
          suggestion: suggestion,
          taskId:this.dudaoTaskId,
        };
        this.$api
          .addSupervise(params)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "督办成功!",
                duration: 2000,
                onClose: () => {
                  this.superviseDialogVisible = false;
                  this.$nextTick(() => {
                    this.taskId = null;
                    this.superviseDialogType = "";
                    this.tableLoading = true;
                    this.init();
                  });
                },
              });
            } else {
              this.$refs.superviseDialog.superviseButtonloading = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
            this.$refs.superviseDialog.superviseButtonloading = false;
          });
      } else {
        this.superviseDialogVisible = false;
        this.$nextTick(() => {
          this.taskId = null;
          this.superviseDialogType = "";
        });
      }
    },
    //延长时间返回事件
    refreshTimeReturnFun(data) {
      if (data.flag) {
        let endDate = data.endDate;
        let params = {
          orderId: this.taskId,
          parkDeadline: endDate,
        };
        this.$api
          .extendTaskDeadLine(params)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "延长时间成功!",
                duration: 2000,
                onClose: () => {
                  this.refreshTimeDialogVisible = false;
                  this.$nextTick(() => {
                    this.taskId = null;
                    this.tableLoading = true;
                    this.init();
                  });
                },
              });
            } else {
              this.$refs.refreshTimeDialog.refreshTimeButtonloading = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {
            this.$refs.refreshTimeDialog.refreshTimeButtonloading = false;
          });
      } else {
        this.refreshTimeDialogVisible = false;
        this.$nextTick(() => {
          this.taskId = null;
        });
      }
    },
    //分享返回事件
    shareReturnFun(data) {
      if (data.flag) {
        let personList = data.personList;
        console.log(personList);
        personList.map((item) => {
          item.id = this.taskId;
        });
        let params = personList;
        this.$api
          .shareAgencyTask(params)
          .then((res) => {
            if (res.data.code == 0) {
              this.$message.success("分享成功");
              this.shareDialogVisible = false;
              this.$nextTick(() => {
                this.taskId = null;
                this.shareType = "";
                // this.searchFun()
              });
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {});
      } else {
        this.shareDialogVisible = false;
        this.$nextTick(() => {
          this.taskId = null;
          this.shareType = "";
        });
      }
    },
    /** 行内-编辑 */
    handleTaskFun(type, row) {
      switch (type) {
        case "withdraw":
          this.$api
            .withdrawAgencyTask(row.orderId)
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "撤回成功!",
                  duration: 2000,
                  onClose: () => {
                    this.tableLoading = true;
                    this.init();
                  },
                });
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch(() => {});
          break;
        case "view":
          this.$router.push({
            path: "/agencyTaskViewDetail",
            query: { id: row.orderId, jumpType: "company",taskId:row.taskId },
          });
          break;
        case "supervise":
          this.taskId = row.orderId;
          this.dudaoTaskId = row.taskId
          this.superviseDialogVisible = true;
          this.superviseDialogType = "single";
          break;
        case "viewAllEvaluate":
          this.$router.push({
            path: "/agencyTaskTotalScoreDetail",
            query: { id: row.orderId, jumpType: "company" },
          });
          break;
        case "viewScore":
          this.$router.push({
            path: "/agencyTaskScoreDetail",
            query: { id: row.orderId, status: row.status, jumpType: "company",isEdit: true},
          });
          break;
        case "share":
          this.taskId = row.orderId;
          this.shareDialogVisible = true;
          this.$nextTick(() => {
            this.shareType = "headquarters";
          });
          break;
        case "refreshTime":
          this.taskId = row.orderId;
          this.refreshTimeDialogVisible = true;
          break;
        case "scoreDetail":
          this.$router.push({
            path: "/agencyTaskScoreDetail",
            query: { id: row.orderId, status: row.status, jumpType: "company",isEdit: false},
          });
          break;
        default:
          break;
      }
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.tableLoading = true;
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("supervisionTopic");
  },
};
</script>

<style lang="scss" scoped>
.contain {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .form-header-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .header-title {
      color: #3d3e4f;
      font-size: 22px;
      font-weight: bold;
    }
    .sheet-btn {
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      width: auto;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
  /deep/.el-table {
    // margin-top: 14px;
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  /deep/.el-switch__label.is-active {
    color: #010334;
  }
  /deep/.el-switch__label {
    color: #010334;
  }
  /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #003685 !important;
    border-color: #003685 !important;
  }
  .table-handle {
    margin-right: 10px;
    margin-left: 0;
  }
  .tableList {
    height: calc(100% - 225px);
  }
  .description-div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .select-input {
    width: 200px;
  }
  .select-time {
    width: 400px;
  }
}
</style>